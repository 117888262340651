.header {
    width: 100%;
    height: 91px;
    background: #0F0D0E;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerHeading {
    display: flex;
    flex-direction: row;
    color: white;
}

.headingKey {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #BFBFBF;
}

.headingValue {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 0.92;
    margin-left: 10px;
}

.summaryBox {
    background: #F9F9F9;
    border: 1px solid #E3E3E3;
    border-radius: 5px;
    margin: 15px 23px
}

.summaryList {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 12px 20px 15px
}

.summaryKey {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #676766;
    margin-left: 10px;

}

.summaryValue {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #0F0D0E;
    margin-right: 10px;
}

.tabsContainer {
    margin: 22px 23px;
    display: flex;
    justify-content: space-around;
}

.noData {
    display: flex;
    justify-content: center;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
}

.tabActive {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    color: #0F0D0E;
    border-bottom: 5px solid #0F0D0E;
    width: 50%;
    height: 30px;
    border-radius: 2px;
}

.tabInactive {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    border-bottom: 5px solid #E7E7E7;
    width: 50%;
    color: #858485;
    height: 30px;
    border-radius: 2px;
}

.cardContainer {
    width: 100%;
    min-height: 118px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.13);
    border-radius: 5px;
    display: flex;
    margin-bottom: 18px;
}

.cardOnline {
    width: 120px;
    background: #009045;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 0px 0px 5px;
    
}

.cardCash {
    width: 120px;
    background: #FFE36F;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 0px 0px 5px;
    
}

.cashText {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0F0D0E;
    margin-left: 5px;
}

.onlineText {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-left: 5px;
}

.cashTypeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    background: #FFCE00;
    border-radius: 5px 0px 0px 0px;
}

.onlineTypeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 47px;
    background: #006F35;
    border-radius: 5px 0px 0px 0px;
}

.tokenNumberCash {
    font-family: 'Inter-Semibold';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0F0D0E;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
}

.tokenNumberOnline {
    font-family: 'Inter-Semibold';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    height: 65px;
}

.itemsTotal {
    font-family: 'Inter-Semibold';
    font-style: normal;
    font-weight: 700;
    font-size: 22.0909px;
    line-height: 27px;
    color: #0F0D0E;
}

.cardDetails {
    width: 55%;
    margin: 14px 12px;
}


.cardTypeTakeAway {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 24px;
    height: 118px;
}

.cardTypeDining {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-right: 10px;
    margin-top: 24px;
    height: 118px;
}

.itemName {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-left: 12px;
    color: #3A3939;
}

.swipeContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center!important;
    background: linear-gradient(87.92deg, #00D666 1.39%, #58FFA8 111.09%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 0px 0px 5px;
    height: 145px;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
}
.cancelContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center!important;
    background: red;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 0px 0px 5px;
    height: 145px;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #FFFFFF; 
}

body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 3px;
    max-width: 600px;
    min-width: 300px;
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}