@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-Medium.ttf) format('opentype');
}
@font-face {
  font-family: 'Inter-Regular';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Light';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-Light.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Semibold';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-SemiBold.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter-Bold';
  src: local('Inter'), url(./assets/fonts/Inter/Inter-Bold.ttf) format('opentype');
}