.header {
    width: 100%;
    height: 91px;
    background: #0F0D0E;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerHeading {
    display: flex;
    flex-direction: row;
    color: white;
}

.headingKey {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #BFBFBF;
}

.headingValue {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    opacity: 0.92;
    margin-left: 10px;
}

.summaryContainer {
    margin: 25% 10%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.heading {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #0F0D0E;
    margin: 25px 47px;

}

.totalAmount {
    width: 268px;
    height: 74px;
    background: #FFD93B;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.total {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    letter-spacing: 0.05em;

    color: #050606;
}

.totalText {

    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    color: #050606;
}

.menuContainer {
    margin-top: 20px;
    width: 100%;
}

.cardContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 20px;
    margin-left: 10px;
}

.cardImage {
    width: 67px;
    height: 68px;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
}

.itemName {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #0F0D0E;
}

.itemPrice {
    margin-top: 9px;
}

.cardDetails {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #0F0D0E;
    margin-left: 15px;
}

.divider {
    width: 270px;
    opacity: 0.5;
    border-bottom: 1px dashed #686868;
    margin: 0px 30px 30px 30px;
}

.orderHeading {

    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #0F0D0E;
}

.totalOrders {
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0px 30px;
    margin-bottom: 20px;
}

.orderValue {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #0F0D0E;
}

.loginAgain {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    text-align: center;
    text-decoration-line: underline;
    color: #FFFFFF;
    margin-bottom: 30px;
}