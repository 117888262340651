.loginContainer {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;

}

.loginContent {
    margin: 20px 10px;
}

.title {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 37.4305px;
    line-height: 45px;
    text-align: center;
    color: #FFFFFF;
}

.caption {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #BFBFBF;
    margin-top: 9px;
    text-align: center;
    margin-bottom: 32px;
}

.input {
    max-width: 300px;
    width: 90%;
    height: 50px;
    background: #2A2C29;
    border-radius: 10px;
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    padding: 0px 16px;
    border: none;
    color: #FFFFFF;
    outline: none;
    margin-bottom: 24px;
}

.label {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #BFBFBF;
    margin-bottom: 10px;
}

.button {
    height: 55px;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #0F0D0E;
    margin-top: 16px;
    outline: none;
    border: none;
    margin-bottom: 15px;
}

.button:disabled {
    background-color: #BFBFBF;
}

.center {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.forgetPwd {
    font-family: 'Inter-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    text-decoration-line: underline;
    color: #FFD93B;
    margin-top: 15px;
    margin-bottom: 20px;
}

.error {
    font-family: 'Inter-Light';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: red;
}